<template>
<div>
    <v-row align="center" class="mb-3">
        <v-col cols="12" md="2">
            <v-btn @click="openAddDialog()" color="primary" large class="secondary--text btn">
                <v-icon class="ml-2">add_circle_outline</v-icon>
                <h3>إضافة {{ this.$route.meta.single }}</h3>
            </v-btn>
        </v-col>
        <v-spacer />
        <v-col cols="12" md="3">
            <v-text-field v-debounce:500ms="getItems" v-model="$global.state.filter.search" label="أبحث هنا"
                color="indigo" hide-details prepend-inner-icon="search" dense outlined filled />
        </v-col>

        <v-col cols="12" md="3">
            <v-autocomplete v-model="$global.state.newsTypeId" @input="getItems()" :items="$global.state.types"
                :loading="$global.state.loading" clearable item-text="name" item-value="id" color="indigo" dense
                label="النوع" no-data-text="لا توجد بيانات" hide-details prepend-inner-icon="date_range" outlined
                filled />
        </v-col>
    </v-row>
    <v-card id="card">
        <v-data-table class="mytable" :items="$global.state.news" :loading="$global.state.loading" :headers="headers"
            :search="$global.state.filter.search" hide-default-footer loading-text="جاري تحميل البيانات"
            no-data-text="لا توجد بيانات" :id="$vuetify.theme.dark ? 'row_colors_dark' : 'row_colors_light'"
            :items-per-page="$global.state.filter.pageSize" @click:row="getNewsDetails" style="cursor: pointer;">

            <template v-slot:item.id="{ item }">
                <span>{{ $global.state.news.indexOf(item) + 1 }}</span>
            </template>

            <template v-slot:item.body="{ item }">
                <div> {{ item.body ? item.body.substring(0, 20) + " ..." : item.body }} </div>
            </template>

            <template v-slot:item.actions="{ item, index }">
                <div style="width: 75px">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn @click.stop="deleteItem(item.id, index)" v-on="on" icon>
                                <v-icon color="error">delete</v-icon>
                            </v-btn>
                        </template>
                        <span>حذف</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn @click.stop="openEditDialog(item)" v-on="on" icon>
                                <v-icon color="info">edit</v-icon>
                            </v-btn>
                        </template>
                        <span>تعديل</span>
                    </v-tooltip>
                </div>
            </template>

        </v-data-table>
    </v-card>
    <div class="d-flex justify-space-between py-3">
        <v-spacer></v-spacer>
        <Pagination />
    </div>
    <Dialog />
</div>
</template>

<script>
export default {
    components: {
        Dialog: () => import("./dialog"),
    },
    data() {
        return {
            headers: [
                {
                    text: "#",
                    value: "id",
                },
                {
                    text: "الخبر",
                    value: "title",
                },
                {
                    text: "الوصف",
                    value: "body",
                },
                {
                    text: "النوع",
                    value: "newsType.name",
                },
                {
                    text: "عدد التعليقات",
                    value: "commentsCount",
                },
                {
                    text: "عدد الاعجابات",
                    value: "allLikesCount",
                },
                {
                    text: "الإجراءات",
                    value: "actions",
                    sortable: false,
                    align: "center",
                },
            ],
        };
    },

    mounted() {
        this.getItems();
        this.$eventBus.$on(`refresh`, () => {
            this.getItems();
        });
    },

    beforeDestroy() {
        this.$eventBus.$off(`refresh`);
    },

    methods: {
        getItems() {
            this.$global.dispatch(
                `get${this.$route.meta.endPoint}`,
                this.$route.meta.role
            );
        },

        getNewsDetails(item) {
            this.$router.push({
                path: `/news-detials/${item.id}`,
                // name: "newsDetails",
                params: {
                    obj: item,
                    // newsId: item.id,
                    // id: item.id
                },
            });
        },

        async deleteItem(id, i) {
            this.$genericService.swalAlertConfirm({}).then((result) => {
                if (result.isConfirmed) {
                    this.$genericService
                        .delete(`${this.$route.meta.endPoint}`, id)
                        .then((res) => {
                            this.$eventBus.$emit(`refresh`);
                            this.$store.dispatch("toastification/setToast", {
                                message: `${res.data.message}`,
                                type: "success",
                            });
                            this.$global.state.news.splice(i, 1);
                        })
                        .catch((err) => {
                            this.$store.dispatch("toastification/setToast", {
                                message: `${err.data.message}`,
                                type: "error",
                            });
                        });
                }
            });
        },

        openAddDialog() {
            var item = {
                dialogType: "add",
            };
            this.$store.commit("setItemDetails", item);
            this.$store.commit("setDialog");
        },

        openEditDialog(item) {
            item.dialogType = "edit";
            this.$store.commit("setItemDetails", item);
            this.$store.commit("setDialog");
            this.$eventBus.$emit("fill-fields");
        },
    },
};
</script>
